import { ClerkApp } from "@clerk/remix";
import { rootAuthLoader } from "@clerk/remix/ssr.server";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  json,
  useLoaderData,
  useRouteError,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import { ConfigProvider } from "antd";
import logo from "./assets/compact-logo.png";

import getServerConfig from "server/config";

import secondcropLogo from "./assets/compact-logo.png";
import styles from "./styles/tailwind.css?url";
import "./styles/clerkOverrides.css";
import "./styles/sentryOverrides.css";
import "./styles/antd.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

import type { LinksFunction, LoaderFunction } from "@remix-run/node";
import clsx from "clsx";
import { useEffect, useState } from "react";
import tailwindConfig from "tailwind.config";

export const links: LinksFunction = () => [{ rel: "stylesheet", href: styles }];

function App() {
  const data = useLoaderData<typeof loader>();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const checkReadyState = () => {
      if (document.readyState === "complete") {
        setIsLoading(false);
      }
    };
    if (document.readyState === "complete") {
      setIsLoading(false);
    } else {
      window.addEventListener("load", checkReadyState);
    }
    return () => window.removeEventListener("load", checkReadyState);
  }, []);

  return (
    <html lang="en" className="h-full bg-white font-main">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="h-full">
        {["staging", "development"].includes(data.deployment) && (
          <div
            className={clsx("fixed top-0 right-0 text-white px-2 py-1 z-50", {
              "bg-yellow-400": data.deployment === "staging",
              "bg-green-400": data.deployment === "development",
            })}
          >
            {data.deployment === "staging" ? "staging" : "local"}
          </div>
        )}
        <hr id="environment" />
        {isLoading && (
          <div className="loading-screen fixed inset-0 bg-white z-50 flex items-center justify-center ">
            <img
              src={secondcropLogo}
              alt="SecondCrop"
              className="w-auto h-12 "
            />
          </div>
        )}
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: tailwindConfig.theme.extend.colors.loamGreen,
            },
          }}
        >
          <Outlet />
        </ConfigProvider>
        <script
          // biome-ignore lint/security/noDangerouslySetInnerHtml: Migrate to biome
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(data.ENV)}`,
          }}
        />
        <ScrollRestoration />
        <Scripts />
        {data.ENV.ANALYTICS_ID.length > 0 && (
          <script
            defer
            src="https://cloud.umami.is/script.js"
            data-website-id={data.ENV.ANALYTICS_ID}
          />
        )}
      </body>
    </html>
  );
}

export const loader: LoaderFunction = (args) => {
  return rootAuthLoader(args, () => {
    const serverConfig = getServerConfig();
    const url = new URL(args.request.url);
    const deployment: "production" | "staging" | "development" =
      serverConfig.NODE_ENV === "production"
        ? url.hostname === "app.staging.secondcrop.com"
          ? "staging"
          : "production"
        : "development";
    return json({
      ENV: {
        ARCGIS_CLIENT_API_KEY: serverConfig.ARCGIS_CLIENT_API_KEY,
        GIT_COMMIT_HASH: serverConfig.GIT_COMMIT_HASH,
        NODE_ENV: process.env.NODE_ENV,
        PANDADOC_CONTRACT_URL: serverConfig.PANDADOC_CONTRACT_URL,
        PANDADOC_EVENT_ENV: serverConfig.PANDADOC_EVENT_ENV,
        SECONDCROP_FIELD_MAP_ATTACHMENTS_MAX:
          serverConfig.SECONDCROP_FIELD_MAP_ATTACHMENTS_MAX,
        MAPBOX_PUBLIC_KEY: serverConfig.MAPBOX_PUBLIC_KEY,
        ANALYTICS_ID: serverConfig.ANALYTICS_ID,
      },
      deployment,
    });
  });
};

export function ErrorBoundary() {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return (
    <html lang="en">
      <head>
        <title>Oops!</title>
        <Meta />
        <Links />
      </head>
      <body>
        <main
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <img
            src={logo}
            alt="SecondCrop"
            style={{
              width: "200px",
              margin: "1rem",
            }}
          />
          <h1
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
            }}
          >
            {isRouteErrorResponse(error)
              ? `${error.status} ${error.statusText}`
              : "Oops! Something went wrong."}
          </h1>
          {!isRouteErrorResponse(error) && (
            <p>{error instanceof Error ? error.message : "Unknown Error"}</p>
          )}
          <a
            href="/"
            style={{
              display: "inline-block",
              padding: "0.5rem 1rem",
              backgroundColor: "#4FAF3D",
              color: "white",
              textDecoration: "none",
              borderRadius: "4px",
              margin: "1rem",
            }}
          >
            Go back home
          </a>
          {process.env.NODE_ENV === "development" && error instanceof Error && (
            <pre
              style={{
                padding: "1rem",
                backgroundColor: "#ffeeee",
                overflowX: "auto",
                fontSize: "0.75rem",
                width: "70%",
              }}
            >
              {error.stack}
            </pre>
          )}
        </main>
        <Scripts />
      </body>
    </html>
  );
}

export default ClerkApp(App);
